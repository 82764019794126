import React from 'react'
import { Link } from "gatsby"
import { Navbar, NavDropdown, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const MenusMainBootstrap = () => {
  return (
    <Navbar collapseOnSelect="true" expand="md">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="Sign Up" id="collasible-nav-dropdown">
            <Link className="dropdown-item" to="/coming-soon">Individuals</Link>
            <Link className="dropdown-item" to="/coming-soon">Educators</Link>
            <Link className="dropdown-item" to="/coming-soon">Companies</Link>
          </NavDropdown>
          <NavDropdown title="About" id="collasible-nav-dropdown">
            <Link className="dropdown-item" to="/coming-soon">About Us</Link>
            <Link className="dropdown-item" to="/coming-soon">News</Link>
            <Link className="dropdown-item" to="/coming-soon">Careers</Link>
          </NavDropdown>
          <NavDropdown title="Support" id="collasible-nav-dropdown">
            <Link className="dropdown-item" to="/coming-soon">Contact Us</Link>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MenusMainBootstrap
