import React from "react"
import {Link} from "gatsby";
import MenusMainBootstrap from "../menus/menus-main-bootstrap"

const AccountMenu = () => {
  return (
    <>
      <MenusMainBootstrap/>
      <div className="login">
        <Link to="/coming-soon">Sign In</Link>
      </div>
    </>
  )
}

export default AccountMenu
