import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.app = app;
      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }

  // getUserProfile({userId, onSnapshot}){
  //   return this.db.collection('publicProfiles')
  //     .where('userId', '==', userId)
  //     .limit(1)
  //     .onSnapshot(onSnapshot)
  // }

  getProfile() {
    const user = this.auth.currentUser
    return this.db.collection('users')
      .doc(user.uid)
      .get()
    // const snapshot = await this.db.collection('users')
    //   .where("email", "==", user.email)
    //   .limit(1)
    //   .get();
    // if (snapshot.empty) {
    //   return false
    // }
    // else {
    //   let data = []
    //   snapshot.forEach(doc => {
    //     data.push(doc.data())
    //   })
    //
    //   return data[0];
    // }
  }

  async initializeUser(user) {
    // if (!user.uid) {
    //   return false
    // }

    this.createFirestoreUser(user)
    
  }

  createFirestoreUser(user) {
    const createUserCallable = this.functions.httpsCallable('createUser');
    return createUserCallable({user});
  }

  updateUserEmail(email) {
    const user = this.auth.currentUser;
    return user.updateEmail(email)
  }

  updateUserPassword(password) {
    const user = this.auth.currentUser;
    return user.updatePassword(password)
  }

  updateProfile(record) {
    const user = this.auth.currentUser;
    return this.db.collection('users')
      .doc(user.uid)
      .update(record.data)
  }

  async isBookmarked(contentId) {
    const user = this.auth.currentUser
    const bookmark = await this.db.collection('users')
      .doc(user.uid)
      .collection('bookmarks')
      .doc(contentId)
      .get()
    console.log(bookmark, 'from firebase')
    return bookmark.exists
  }


  removeBookmark(contentId) {
    const user = this.auth.currentUser;
    return this.db.collection('users')
      .doc(user.uid)
      .collection('bookmarks')
      .doc(contentId)
      .delete()
  }

  addBookmark(contentId) {
    const user = this.auth.currentUser;
    return this.db.collection('users')
      .doc(user.uid)
      .collection('bookmarks')
      .doc(contentId)
      .set({
        date: new Date(),
        contentId
      })
  }

  // async register({email, password, username}) {
  //   await this.auth.createUserWithEmailAndPassword(email, password);
  //   const createProfileCallable = this.functions.httpsCallable('createPublicProfile');
  //   return createProfileCallable({
  //     username
  //   })
  // }
  async register(user) {
    await this.auth.createUserWithEmailAndPassword(user.email, user.password);

    await this.initializeUser(user)

    // const createProfileCallable = this.functions.httpsCallable('createPublicProfile');
    // return createProfileCallable({
    //   username
    // })
  }

  // async postComment({text, bookId}){
  //   const postCommentCallable = this.functions.httpsCallable('postComment');
  //   return postCommentCallable({
  //     text,
  //     bookId
  //   });
  // }

  // subscribeToBookComments({bookId, onSnapshot}){
  //   const bookRef = this.db.collection('books').doc(bookId);
  //   return this.db.collection('comments')
  //     .where('book', '==', bookRef)
  //     .orderBy('dateCreated', 'desc')
  //     .onSnapshot(onSnapshot)
  // }

  // async login({email, password}) {
  //   return this.auth.signInWithEmailAndPassword(email, password);
  // }

  async logout() {
    await this.auth.signOut();
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
