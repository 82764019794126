import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import AccountMenu from "../components/Firebase/account-menu"
import HeaderLogo from "../../static/assets/images/inventing-heron-w-icon_white.svg"

import "./header.css"

const Header = ({ siteTitle }) => (
  <div className="header" role="banner">
    <div className="inner inner-header" style={{ display: 'flex' }}>
      <div className="logo">
        <Link to="/">
          <img className="logo-image" src={HeaderLogo} alt={siteTitle} />
        </Link>
      </div>
      <AccountMenu />
    </div>

  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
