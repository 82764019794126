const firebaseConfig = {
  apiKey: "AIzaSyAS8ZnOfIDT0_JV1auLR6UUofn8ATzVEb0",
  authDomain: "ih-test-82bc0.firebaseapp.com",
  databaseURL: "https://ih-test-82bc0.firebaseio.com",
  projectId: "ih-test-82bc0",
  storageBucket: "ih-test-82bc0.appspot.com",
  messagingSenderId: "875147808210",
  appId: "1:875147808210:web:536a21d6f38521a67a0c0b"
};

export default firebaseConfig;

