import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLinkedinIn as fabLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import FooterLogo from "../../static/assets/images/inventing-heron-w-icon_black.svg"

import "./footer.css"

const Footer = ({ siteTitle }) => (
  <div className="footer">
    <div className="inner inner-footer">

      <div className="footer-primary">
        <span className="logo">
          <Link to="/">
            <img className="logo-image" src={FooterLogo} alt={siteTitle} />
          </Link>
        </span>

        <nav className="navigation">
          <ul className="menu-secondary menu-secondary-level-0">
            <li>
              <span role="button">Sign Up</span>
              <ul className="menu-secondary menu-secondary-level-1">
                <li>
                  <Link to="/coming-soon">Individuals</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Educators</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Companies</Link>
                </li>
              </ul>
            </li>
            <li>
              <span role="button">About</span>
              <ul className="menu-secondary menu-secondary-level-1">
                <li>
                  <Link to="/coming-soon">About Us</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Careers</Link>
                </li>
                <li>
                  <Link to="/coming-soon">News</Link>
                </li>
              </ul>
            </li>
            <li>
              <span role="button">Support</span>
              <ul className="menu-secondary menu-secondary-level-1">
                <li>
                  <Link to="/coming-soon">Contact Us</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <ul className="social-links">
          <li><a aria-label="LinkedIn" href="https://www.linkedin.com/company/the-inventing-heron-project"><FontAwesomeIcon icon={fabLinkedinIn} /></a></li>
          <li><a aria-label="Twitter" href="https://twitter.com/InventingHeron"><FontAwesomeIcon icon={fabTwitter} /></a></li>
          <li><a aria-label="Facebook" href="https://www.facebook.com/InventingHeron"><FontAwesomeIcon icon={fabFacebook} /></a></li>
        </ul>
      </div>

      <div className="footer-secondary">
        <div className="copyright">
          &copy; Copyright 2020 Inventing Heron
        </div>

        <ul className="page-links">
          <li>
            <Link to="/coming-soon">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/coming-soon">Terms of Use</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Footer
